import { Injectable, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthV2Service } from '@jfw-library/ecommerce/core';
import { UserEcomSettings } from 'common-types';
import {
  Observable,
  Subject,
  catchError,
  from,
  merge,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { UserEcomSettingsApi } from './user-ecom-settings-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserEcomSettingsService {
  private authV2Service = inject(AuthV2Service);

  constructor(private userEcomSettingsApi: UserEcomSettingsApi) {}

  private getUserEcomSettingsSubject = new Subject<User | null>();

  private _currentUserEcomSettingsCache$:
    | Observable<UserEcomSettings | undefined>
    | undefined;

  get currentUserEcomSettings$() {
    if (!this._currentUserEcomSettingsCache$) {
      this._currentUserEcomSettingsCache$ = merge(
        this.authV2Service.authState$,
        this.getUserEcomSettingsSubject
      ).pipe(
        switchMap((user) =>
          !!user && !!user?.email && !!user?.uid
            ? from(this.getUserEcomSettings()).pipe(
                catchError((error) => {
                  console.log('Error getting dealerUser:', error);
                  return of(undefined);
                })
              )
            : of(undefined)
        ),
        tap((userEcomSettings) =>
          console.log(
            'UserEcomSettings received from server:',
            userEcomSettings
          )
        ),
        shareReplay(1)
      ); // => gets value from the user
    }
    return this._currentUserEcomSettingsCache$;
  }

  public refreshUserEcomSettings() {
    const user = this.authV2Service.currentUser;
    this.getUserEcomSettingsSubject.next(user);
  }

  public async getUserEcomSettings(): Promise<UserEcomSettings | undefined> {
    return await this.userEcomSettingsApi.apiGetEcomSettingsForUser();
  }

  public async updateUserEcomSettings(
    settings: UserEcomSettings
  ): Promise<any> {
    return await this.userEcomSettingsApi.apiUpdateEcomSettingsForUser(
      settings
    );
  }
}
